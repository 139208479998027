/* eslint-disable no-mixed-spaces-and-tabs */
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Theme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Form, Formik } from "formik";
import MuiPhoneNumber from "mui-phone-number";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReadOTP } from "react-read-otp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { getPasscode, verifyPasscode } from "../../../../export/web-worker";
import { colors } from "../../../config/colors";
import LoadingButton from "../../../design/wilya-loading-button";
import useWebWorkerData from "../controllers/use-web-worker-data";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo: string = require("../assets/logo_wilya_dark.svg").default;

const useStyles = makeStyles(() => ({
	pageOuterContainer: {
		backgroundColor: "#eeeeee",
	},
	pageContainer: {
		backgroundColor: "#ffffff",
		height: "101.5vh",
		marginTop: "-12px",
		paddingTop: "5vh",
	},
	appBar: {
		zIndex: 1200 + 1,
		backgroundColor: "#303030",
	},
	menuButton: {
		marginRight: "16px",
	},
	title: {
		flexGrow: 1,
	},
	appBarButtons: {
		color: "#fff",
	},
	paperButton: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "220px",
		height: "220px",
		marginTop: "16px",
		marginBottom: "16px",
		borderRadius: 10,
		backgroundColor: "#eeeeee",
		borderBottom: "12px solid orange",
	},
	buttonOne: {
		padding: 12,
		height: "100%",
		alignItems: "flex-start",
	},
	buttonTwo: {
		marginTop: "16px",
		marginBottom: "16px",
		marginLeft: "auto",
		marginRight: "auto",
		width: "200px",
		height: "200px",
		backgroundColor: "#303030",
		color: "#fff",
		borderRadius: 10,
	},
	buttonBox: {
		color: "#000",
	},
	btnChip: {
		alignSelf: "flex-end",
		backgroundColor: "#424242",
		color: "#fff",
	},
	btnChip2: {
		visibility: "hidden",
	},
	btnMain: {
		alignSelf: "flex-start",
		textAlign: "left",
		marginTop: 12,
	},
	btnSecondary: {
		alignSelf: "flex-start",
		textAlign: "left",
		marginTop: 12,
	},
	errorMessage: {
		color: "#ff1744",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
	passcodeMessage: {
		color: "#03C04A",
	},
	helpBtn: {
		color: "#000 !important",
	},
	resendPasscode: {
		textTransform: "capitalize",
		fontFamily: "Helvetica,Helvetica Neue,Roboto,Arial,sans-serif",
		fontSize: "14px",
		fontWeight: "600",
		lineHeight: "10px",
		marginLeft: "50%",
		transform: "translateX(-50%)",
		color: "#4e87ea",
	},
	resendPasscodeDisabled: {
		textTransform: "capitalize",
		fontFamily: "Helvetica,Helvetica Neue,Roboto,Arial,sans-serif",
		fontSize: "14px",
		fontWeight: "600",
		lineHeight: "10px",
		color: "rgba(0, 0, 0, 0.26)",
	},
	submit: {
		zIndex: "3 !important",
		position: "relative",
	},
	form: {
		zIndex: 3,
		background: "#fff",
		paddingBottom: "48px",
	},
}));

export default function WorkersLogin() {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	// const { guid } = useParams();
	// const [isPhoneValid, setIsPhoneValid] = useState(true)
	const [phoneNumber, setPhoneNumber] = useState("");
	const [passCode, setPassCode] = useState("");
	const [status, setStatus] = useState("idle");
	const [phoneDisabled, setPhoneDisabled] = useState(false);
	const [enabledPassCodeListener, setEnabledPassCodeListener] = useState(false);
	useReadOTP(setPassCode, { enabled: enabledPassCodeListener });

	const {
		refreshStatus,
		authenticated,
		verifyPasscodeError,
		verifyPasscodeStatus,
		getPasscodeError,
		getPasscodeStatus,
		verifyPasscodeIsLocked,
		getPasscodeErrorCode,
	} = useWebWorkerData();

	// useEffect(() => {
	//   if (status === "pre-enter") setStatus("re-enter");
	// }, [enabledPassCodeListener, status]);

	//COMMENTED OUT TO AVOID ERROR ON LAUNCH

	// useEffect(() => {
	// 	if (authenticated) console.log("AUTHENTICATED: ", authenticated);
	// 	else {
	// 		// console.log('not authenticated ', authenticated)
	// 		dispatch(Refresh());
	// 	}
	// }, []); // eslint-disable-line

	useEffect(() => {
		if (refreshStatus === "fulfilled") navigate("/w");
	}, [refreshStatus]); // eslint-disable-line

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setStatus("pending");
		setPhoneDisabled((prevState) => true);
		const phone = phoneNumber.split("").reduce((acc, curr) => {
			if (Number.isInteger(Number.parseInt(curr))) {
				acc += curr;
			}
			return acc;
		}, "+");
		if (passCode === "") {
			setEnabledPassCodeListener(true);
			dispatch(getPasscode({ workerPhoneNumber: phone }));
			// axios.post('https://gatdev.azure-api.net/api/Workers/GetPassCode',
		} else if (passCode.trim().length > 0 && phoneNumber.trim().length > 0) {
			// send post request to
			// axios.post('https://gatdev.azure-api.net/api/Workers/VerifyPassCode',

			dispatch(verifyPasscode({ workerPhoneNumber: phone, passCode }));
			// console.log('sending phone number alogn with passCode: ', { workerPhoneNumber: phoneNumber, passCode })
		}
	};
	//  NOTE: timer controls
	const [timerValue, setTimerValue] = useState(29);
	let timerId: NodeJS.Timeout | null = null;
	const startTimer = () => {
		// console.log('starting timer')
		if (timerValue === 0 && timerId !== null) clearInterval(timerId);
		timerId = setInterval(() => {
			setTimerValue((prevState) => {
				if (prevState - 1 > 0) {
					return --prevState;
				} else {
					if (timerId !== null) clearInterval(timerId);
					return 0;
				}
				// prevState-1>0? --prevState:0
			});
		}, 1000);
	};

	useEffect(() => {
		// console.log('getPasscodeStatus: ', getPasscodeStatus)
		if (getPasscodeStatus === "register") setStatus("register");
		if (getPasscodeStatus === "fulfilled idle") {
			setStatus("pre-enter");
			clearInterval(timerId!);
			startTimer();
		} else if (getPasscodeStatus === "fulfilled") {
			setEnabledPassCodeListener(false);
			clearInterval(timerId!);
			startTimer();
			setPhoneDisabled(true);
		} else if (getPasscodeStatus === "error" && status !== "changed") {
			setEnabledPassCodeListener(false);
			setStatus("pre-pre-enter");
		}
	}, [getPasscodeStatus]); // eslint-disable-line

	useEffect(() => {
		// console.log('verifyPasscodeStatus: ', verifyPasscodeStatus)
		if (
			verifyPasscodeStatus === "fulfilled passcode" ||
			refreshStatus === "fulfilled"
		) {
			navigate("/w");
		}
		// window.location.replace('/w')
	}, [verifyPasscodeStatus]); // eslint-disable-line

	const phoneRegExp =
		/^([+]{1}(([0-9]{1,3} ?[0-9]{10})))$|^(([0-9]{10}))$|^([+]{1}([0-9]{1,3} ?([0-9]{3}-?[0-9]{3}-?[0-9]{4})))$|^((([0-9]{3}-?[0-9]{3}-?[0-9]{4})))$/gm;

	return (
		<>
			<Container
				maxWidth={false}
				disableGutters
				className={classes.pageOuterContainer}
			>
				<Container
					maxWidth='sm'
					disableGutters
					className={classes.pageContainer}
				>
					{/*{(status !== "register") &&  <Box */}
					{/*   display="flex"*/}
					{/*   flexDirection="row"*/}
					{/*   justifyContent="flex-end"*/}
					{/*   alignItems="center"*/}
					{/*   minWidth="40%"*/}
					{/*   marginBottom="68px"*/}
					{/* >*/}
					{/*   <Language />*/}
					{/* </Box> }*/}

					<Toolbar
						// maxWidth='80%'
						sx={{
							textAlign: "center",
							display: "flex",
							justifyContent: "center",
							marginTop: "12px",
							marginBottom: "32px",
						}}
					>
						<img src={logo} alt='' height={75} />
					</Toolbar>
					<Box
						display='flex'
						flexDirection='column'
						justifyContent='center'
						ml='auto'
						mr='auto'
						maxWidth='80%'
					>
						{status !== "register" && (
							<>
								{" "}
								<Typography
									style={{ textAlign: "center", fontWeight: "500" }}
									mt={5}
									variant='h6'
								>
									{t("login_phone-number")}
								</Typography>
								<Formik
									initialValues={{ username: "" }}
									validationSchema={Yup.object({
										username: Yup.string().matches(
											phoneRegExp,
											"Phone Number is not valid"
										),
										// .email("Invalid email address")
										// .required("Email is required"),
									})}
									onSubmit={(values, { setSubmitting }) => {
										// dispatch(loginUser(values));
										setStatus("pending");
										// dispatch()
										// setSubmitting(false);
									}}
								>
									{() => (
										<Form
											className={classes.form}
											onSubmit={(e) => handleSubmit(e)}
										>
											<MuiPhoneNumber
												id='username'
												placeholder='Phone Number'
												name='phone number'
												type='text'
												label='Phone Number'
												fullWidth
												variant='outlined'
												margin='normal'
												defaultCountry={"us"}
												onlyCountries={[
													"us",
													"in",
													"om",
													"mx",
													"gb",
													"br",
													"ph",
												]}
												disableAreaCodes={true}
												disabled={
													(getPasscodeStatus === "pending" ||
														getPasscodeStatus === "fulfilled" ||
														verifyPasscodeStatus === "pending" ||
														verifyPasscodeStatus === "error passcode") &&
													phoneDisabled
												}
												sx={{ "& svg": { height: "1em" } }}
												onChange={(e: any) => setPhoneNumber(e)}
											/>
											{getPasscodeStatus === "error" &&
											status !== "changed" &&
											getPasscodeError !== "" ? (
												<Box
													mb={1}
													sx={{
														display: "flex",
														padding: "12px",
														flexDirection: "row",
														backgroundColor: "#FBEAEA",
														borderRadius: "4px",
													}}
												>
													<ErrorOutlineOutlinedIcon sx={{ color: "#E44B48" }} />
													<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontWeight: 400,
																fontSize: "14px",
																color: "#541313",
															}}
														>
															{getPasscodeError}
														</Typography>
													</Box>
												</Box>
											) : null}

											{/* sms disclaimer */}
											{((getPasscodeStatus !== "fulfilled" &&
												getPasscodeStatus !== "fulfilled idle") ||
												status === "previous") &&
												status !== "resend" && (
													<Box
														style={{
															width: "100%",
														}}
													>
														<Typography
															sx={{
																fontFamily: "Roboto",
																color: colors.disclaimer,
																fontWeight: 500,
																lineHeight: "20px",
																fontSize: "12px",
																textAlign: "center",
															}}
														>
															{t("login_consent-text")}
														</Typography>
													</Box>
												)}

											{(getPasscodeStatus === "fulfilled" ||
												getPasscodeStatus === "fulfilled idle" ||
												passCode.trim().length > 0) && (
												<Field
													style={{ marginTop: "16px" }}
													id='passcode'
													component={TextField}
													placeholder='passcode'
													name='passcode'
													type='text'
													label='Passcode'
													fullWidth
													variant='outlined'
													margin='normal'
													value={passCode}
													validate={(value: string) => {
														return value.trim().length <= 4
															? value.trim()
															: "0000";
													}}
													onChange={(e: any) => {
														if (e.target.value.trim().length <= 4) {
															setPassCode(e.target.value.trim());
															if (status === "pre-enter") setStatus("re-enter");
														} else {
															setPassCode(
																e.target.value.trim().substring(0, 4)
															);
															if (status === "pre-enter") setStatus("re-enter");
														}
													}}
												/>
											)}
											{(getPasscodeStatus === "fulfilled" ||
												getPasscodeStatus === "fulfilled idle") &&
											passCode.trim().length === 0 ? (
												<Box display='flex' justifyContent='center' mt={1}>
													<Typography
														className={classes.passcodeMessage}
														component='p'
														variant='subtitle2'
														style={{ textAlign: "center" }}
													>
														{/* The passcode has been sent to your phone, please enter the passcode here to login. */}
														{t("login_enter-passcode")}
														{/* The Passcode will be sent to your registered phone number. */}
													</Typography>
												</Box>
											) : null}
											{verifyPasscodeStatus === "error passcode" &&
											getPasscodeStatus !== "fulfilled idle" &&
											status !== "re-enter" &&
											passCode.trim().length >= 4 ? (
												<Box
													mt={1}
													sx={{
														display: "flex",
														padding: "12px",
														flexDirection: "row",
														backgroundColor: "#FBEAEA",
														borderRadius: "4px",
													}}
												>
													<ErrorOutlineOutlinedIcon sx={{ color: "#E44B48" }} />
													<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontWeight: 400,
																fontSize: "14px",
																color: "#541313",
															}}
														>
															{verifyPasscodeError}
														</Typography>
													</Box>
												</Box>
											) : null}
											<LoadingButton
												type='submit'
												fullWidth
												variant='contained'
												color='primary'
												className={classes.submit}
												size='large'
												loading={
													verifyPasscodeStatus === "pending" ||
													getPasscodeStatus === "pending"
												}
												loadingPosition='start'
												style={{ marginTop: "24px" }}
												disabled={
													verifyPasscodeIsLocked ||
													getPasscodeStatus === "pending" ||
													verifyPasscodeStatus === "pending"
														? true
														: getPasscodeStatus === "idle" ||
														  verifyPasscodeStatus === "idle"
														? phoneNumber.length < 10
															? true
															: getPasscodeStatus === "idle" ||
															  getPasscodeStatus === "register"
															? false
															: passCode.trim().length < 4
														: getPasscodeStatus === "pending" ||
														  verifyPasscodeStatus === "pending"
														? true
														: phoneNumber.length < 10
														? true
														: passCode.trim().length < 4
												}
												onClick={(e: any) => handleSubmit(e)}
												loadingIndicator={
													<CircularProgress
														size={20}
														sx={{ color: "rgba(0, 0, 0, 0.38)" }}
													/>
												}
												startIcon={<></>}
											>
												{getPasscodeStatus === "pending"
													? t("login_verify-phone-number")
													: verifyPasscodeStatus === "pending"
													? t("login_verify-passcode")
													: t("login_next")}
												{status === "fulfilled"}
											</LoadingButton>

											{getPasscodeStatus === "fulfilled" ? (
												<Box
													sx={{
														display: "flex",
														marginTop: "12px",
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "stretch",
													}}
												>
													{timerValue !== 0 && (
														<>
															{/* <TimerIcon sx={{fontSize: 22}} className={classes.timer}/> */}
															<Typography color='rgba(0, 0, 0, 0.26)'>
																{t("login_resent-passcode-timer-text")}
															</Typography>
															<Typography
																ml='5px'
																color='rgba(0, 0, 0, 0.26)'
																fontWeight='600'
															>
																{timerValue}
															</Typography>
															<Typography
																ml='1x'
																mr='10px'
																fontWeight='600'
																color='rgba(0, 0, 0, 0.26)'
															>
																{t("login_resent-passcode-timer-text-s")}
															</Typography>
														</>
													)}
													{timerValue === 0 && !verifyPasscodeIsLocked && (
														<Button
															disableElevation
															variant='text'
															className={classes.resendPasscode}
															onClick={(e) => {
																if (phoneNumber.trim().length > 0) {
																	clearInterval(timerId!);
																	setTimerValue((prevState) => 29);
																	handleSubmit(e);
																}
															}}
														>
															{t("login_resent-passcode")}
														</Button>
													)}
												</Box>
											) : null}
										</Form>
									)}
								</Formik>{" "}
							</>
						)}

						{status === "register" && (
							<>
								<Typography
									style={{ textAlign: "center", fontWeight: "400" }}
									mt={8}
									variant='subtitle1'
								>
									{t("login_register-msg")}
								</Typography>

								<Typography
									variant='subtitle1'
									onClick={() => window.open("/register-here")}
									component='p'
									style={{
										textDecoration: "underline",
										cursor: "pointer",
										userSelect: "none",
										textAlign: "center",
										color: "#24a0ed",
									}}
									mt={6}
								>
									{t("login_register-link")}
								</Typography>

								<LoadingButton
									type='submit'
									fullWidth
									variant='contained'
									color='primary'
									className={classes.submit}
									size='medium'
									style={{ marginTop: "24px" }}
									onClick={() => {
										setStatus("idle");
										setPhoneNumber("");
									}}
								>
									{t("login_return-to-login")}
								</LoadingButton>
							</>
						)}
						{/*  background: '#222', */}
						<Box
							ml='auto'
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								color: "tomato",
								left: "50%",
								transform: "translate(-50%)",
								bottom: "36px",
								position: "fixed",
							}}
						>
							{/* <IconButton
                          style={{background: "#24a0ed", right: '24px'}}
                            onClick={() => window.open("/help-support")}
                            className={classes.appBarButtons}
                          >
                            <HelpCenterIcon className={classes.helpButton} />
                          </IconButton> */}

							<Typography
								variant='subtitle1'
								onClick={() => window.open("/help-support")}
								component='p'
								style={{
									fontFamily: "Roboto",
									color: "rgba(0, 0, 0, 0.6)",
									textDecoration: "underline",
									cursor: "pointer",
									userSelect: "none",
									marginBottom: "12px",
									lineHeight: "20px",
									fontSize: "14px",
									fontWeight: 400,
									textAlign: "center",
								}}
							>
								{t("login_contact-support")}
							</Typography>

							<Typography
								variant='subtitle1'
								onClick={() =>
									window.open("https://wilya.com/privacy-policy/", "_blank")
								}
								component='p'
								style={{
									fontFamily: "Roboto",
									color: "rgba(0, 0, 0, 0.6)",
									textDecoration: "underline",
									cursor: "pointer",
									userSelect: "none",
									lineHeight: "20px",
									fontSize: "14px",
									fontWeight: 400,
									textAlign: "center",
								}}
							>
								{t("privacy_policy_text")}
							</Typography>
						</Box>
					</Box>
				</Container>
				{/* <SmartBanner /> */}
			</Container>
		</>
	);
}
